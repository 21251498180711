@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
	height: 100%;
}

body {
	background-color: black;
}

.no-sb::-webkit-scrollbar {
	display: none;
}

.no-sb {
	scrollbar-width: none;
}

@keyframes reuse-anim {
	0% {
		transform: rotateY(0deg);
	}
	75% {
		transform: rotateY(0deg);
	}
	100% {
		transform: rotateY(180deg);
	}
}

.svg-elem {
	animation: reuse-anim 4s infinite ease-in-out;
}

@keyframes reuse-anim-text {
	0% {
		max-height: 0;
	}
	20% {
		max-height: 100px;
	}
	87.3% {
		max-height: 100px;
	}
	87.4% {
		max-height: 0;
	}
	100% {
		max-height: 0;
	}
}

.reduce-elem {
	overflow: hidden;
	max-height: 0;
	fill: none;
	animation: reuse-anim-text 4s infinite ease-in-out;
}
